export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/durbar-logo.jpg',
    banner_img_root : '/img/world-cafe-banner-2.png',
    resid : 'e54a4aa39226aadd29f3e6b53c5baa98',
    key_value : 'durbarbistrolakewood',
    secret_value : 'durbarbistrolakewood',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3068.5572715336457!2d-105.13160188512813!3d39.72713130521392!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b840307ac6557%3A0x256a1964e9c5516b!2s11903%20W%206th%20Ave%2C%20Lakewood%2C%20CO%2080401%2C%20USA!5e0!3m2!1sen!2sin!4v1591179342335!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Durbar Bistro"
};
