import React from 'react'
import { useSelector } from 'react-redux';
import {config} from '../config';

function BannerNew(){
  const restaurantInformation_data = useSelector(state =>state.RestaurantInformation)
  console.log('restdata', restaurantInformation_data);
  const config_data = useSelector(state =>state.Config)
  return(
    <>
    {restaurantInformation_data &&
      Object.keys(restaurantInformation_data).length > 0 &&
      restaurantInformation_data.restaurant_info &&
      restaurantInformation_data.restaurant_info.object &&
      restaurantInformation_data.restaurant_info.request_status === true &&
      Object.keys(restaurantInformation_data.restaurant_info.object).length > 0 ?

      (
      <div className="row banner digitalmenu" style={{backgroundImage: 'url(' + restaurantInformation_data.restaurant_info.object.BANNER + ')'}}>
      <div className="banner-overlay">
        <div className="container">
          <div className="banner-info">
          <br/><br/><br/>
            <div className="row">
              <div className="col-xs-12 col-lg-6 col-md-6 banner-left">
              {restaurantInformation_data.restaurant_info.object.LOGO ?
                (
                <img src={restaurantInformation_data.restaurant_info.object.LOGO} alt="images not found" />
                ) : null}
              </div>
              <div className="col-xs-12 col-lg-6  col-md-6 banner-right" >
                <div className="row">
                  <div className="col-xs-12 restaurant-info">
                  <br/>
                    <h3>{restaurantInformation_data.restaurant_info.object.name} - Digital Menu</h3>
                    <p>{restaurantInformation_data.restaurant_info.object.tagLine ? restaurantInformation_data.restaurant_info.object.tagLine : null}</p>
                    <p>{restaurantInformation_data.restaurant_info.object.address_address},  {restaurantInformation_data.restaurant_info.object.address_city}, {restaurantInformation_data.restaurant_info.object.name_point}</p>
                  </div>

                  <div className ="col-lg-4 col-md-4"></div>
                </div>


              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    ): null
   }
    </>
  )
}

export default BannerNew